import '../../css/VendorDashboard.css';
import '../../css/Invoices.css';
import React, { Component } from 'react';
import { Row } from 'reactstrap';

import InvoiceTable from './desktop/InvoicesTable';
import InvoicesCards from "./mobile/InvoicesCards";
import { updateInvoicesListActivePage, updateInvoicesPerPage, fetchInvoices } from "../../actions/InvoiceActions";
import { Responsive, Dropdown, Pagination, Segment } from "semantic-ui-react";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import FirstPaginationIcon from '../../images/icons/pagination/first_page.svg';
import LastPaginationIcon from '../../images/icons/pagination/last_page.svg';
import NextPaginationIcon from '../../images/icons/pagination/next_page.svg';
import PreviousPaginationIcon from '../../images/icons/pagination/previous_page.svg';

const dropdownOptions = [
  { key: 10, text: "10", value: 10 },
  { key: 25, text: "25", value: 25 },
  { key: 30, text: "30", value: 30 },
  { key: 50, text: "50", value: 50 }
]

class Invoices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    }
  }

  componentDidMount() {
    this.loadTableData(this.props.invoicesListActivePage, this.props.invoicesListInvoicesPerPage);
  }


  loadTableData(pageNumber, invoicesPerPage) {
    const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();
    var opts = {
      'vendorIDBlacklist': disabledVendors.map(vendor => vendor.vendorID),
      'invoicesPerPage': invoicesPerPage,
      'currentPage': pageNumber
    };
    this.props.actions.fetchInvoices({ opts: opts });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoicesListInvoicesPerPage !== this.props.invoicesListInvoicesPerPage
      || prevProps.invoicesListActivePage !== this.props.invoicesListActivePage ||
      prevProps.vendors !== this.props.vendors) {
      const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();
      var opts = {
        'vendorIDBlacklist': disabledVendors.map(vendor => vendor.vendorID),
        'invoicesPerPage': this.props.invoicesListInvoicesPerPage,
        'currentPage': this.props.invoicesListActivePage
      };
      this.props.actions.fetchInvoices({ opts: opts });
    }
  }

  render() {
    const { currentPage } = this.state;
    const { totalInvoiceNumber, invoicesListInvoicesPerPage, invoiceData, fetchInvoicesInProgress, actions, user, vendors } = this.props;
    if (!invoiceData) {
      return <LoadingSpinner size={80} />
    }

    const noInvoices = totalInvoiceNumber === 0;

    return (
      <div className="mh-100 minh-100 invoices-container" style={{ overflow: "auto" }}>

        <Row className="summary-title-row">
          <div className="col-sm-12">
              <h1 data-cy="page-title" className="d-none d-md-block no-padding" style={{ color: "grey" }}>Invoices</h1>
              <h3 data-cy="page-title" className="d-md-none" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>Invoices</h3>
          </div>
        </Row>
        <Row className="d-block invoice-table-wrapper">

          {noInvoices && <Responsive className="order-review-container">
            <div className="mh-100 minh-100">
              <div className="row justify-content-center mh-100 minh-100">
                <div data-cy="no-invoices-message" className="col text-center no-reviews-content">
                  <h1 style={{color: "grey"}}>No invoices</h1>
                </div>
              </div>
            </div>
          </Responsive>}


          {!noInvoices && <Segment basic loading={fetchInvoicesInProgress} style={{padding: "0px"}}>
            <Responsive {...Responsive.onlyComputer} loading={fetchInvoicesInProgress.toString()}>
              <InvoiceTable invoices={invoiceData} fetchInvoicesInProgress={fetchInvoicesInProgress} user={user} vendors={vendors} />
              <Pagination activePage={currentPage}
                          totalPages={Math.ceil(totalInvoiceNumber / invoicesListInvoicesPerPage) || 1}
                          className="invoices-pagination-control"
                          onPageChange={(event, data) => {
                          actions.updateInvoicesListActivePage(data.activePage - 1);
                          this.setState({ currentPage: data.activePage });
                          }}
                          firstItem={{content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
                          lastItem={{content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
                          nextItem={{content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
                          prevItem={{content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}
              />
              <Dropdown compact downward placeholder={"" + invoicesListInvoicesPerPage} selection options={dropdownOptions} className="items-dropdown"
                onChange={(event, data) => actions.updateInvoicesPerPage(data.value)} />
            </Responsive>
            <Responsive {...Responsive.onlyTablet} loading={fetchInvoicesInProgress.toString()}>
              <Dropdown compact downward placeholder={"" + invoicesListInvoicesPerPage}
                        selection options={dropdownOptions}
                        className="items-dropdown tablet"
                        onChange={(event, data) => actions.updateInvoicesPerPage(data.value)} />
              <div className="clear"/>
              <InvoicesCards invoices={invoiceData} fetchInvoicesInProgress={fetchInvoicesInProgress} user={user} vendors={vendors} />
              <Pagination activePage={currentPage}
                          totalPages={Math.ceil(totalInvoiceNumber / invoicesListInvoicesPerPage) || 1}
                          className="invoices-pagination-control tablet"
                          onPageChange={(event, data) => {
                            actions.updateInvoicesListActivePage(data.activePage - 1);
                            this.setState({ currentPage: data.activePage });
                          }}
                          firstItem={{content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
                          lastItem={{content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
                          nextItem={{content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
                          prevItem={{content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}/>

            </Responsive>
            <Responsive {...Responsive.onlyMobile} loading={fetchInvoicesInProgress.toString()} >
              <Dropdown compact downward placeholder={"" + invoicesListInvoicesPerPage}
                        selection options={dropdownOptions}
                        className="items-dropdown tablet"
                        onChange={(event, data) => actions.updateInvoicesPerPage(data.value)} />
              <div className="clear"/>
              <InvoicesCards invoices={invoiceData} fetchInvoicesInProgress={fetchInvoicesInProgress} user={user} vendors={vendors} />
              <Pagination
                  activePage={currentPage}
                  totalPages={Math.ceil(totalInvoiceNumber / invoicesListInvoicesPerPage) || 1}
                  className="invoices-pagination-control tablet"
                  onPageChange={(event, data) => {
                  actions.updateInvoicesListActivePage(data.activePage - 1);
                  this.setState({ currentPage: data.activePage });
                  }}
                  nextItem={false}
                  prevItem={false}
                  lastItem={false}
                  firstItem={false}
              />
            </Responsive>
          </Segment>}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageNumber: state.invoices.pageNumber,
    vendors: state.vendor.vendors,
    invoiceData: state.invoices.invoiceData,
    totalInvoiceNumber: state.invoices.totalInvoiceNumber,
    invoicesListInvoicesPerPage: state.invoices.invoicesListInvoicesPerPage,
    fetchInvoicesInProgress: state.invoices.fetchInvoicesInProgress,
    invoicesListActivePage: state.invoices.invoicesListActivePage,
    user: state.user.user
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    actions: bindActionCreators({ fetchInvoices, updateInvoicesListActivePage, updateInvoicesPerPage }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToState)(Invoices);