import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrdersCostSummary from "./order-cost/OrdersCostSummary";
import OrderNumberSummary from "./order-number/OrderNumberSummary";
import OrderPaymentTypeSummary from "./payment-type/OrderPaymentTypeSummary";
import OrderDeliveryTypeSummary from "./delivery-type/OrderDeliveryTypeSummary";
import { fetchOrderStats } from "../../../actions/OrderActions";
import { Grid, Responsive, Segment } from "semantic-ui-react";
import LoadingSpinner from '../../../components/loading/LoadingSpinner';

class OrdersSummary extends Component {

    componentDidMount() {
        const { vendors, ordersListStartDate, ordersListEndDate } = this.props;
        const { fetchOrderStats } = this.props.actions;
            const [...disabledVendors] = vendors.filter((vendor) => { return vendor.selected === false }).values();
            fetchOrderStats({
                opts: {
                    toDate: ordersListEndDate,
                    vendorIDBlacklist: disabledVendors.map(vendor => vendor.vendorID)
                },
                fromDate: ordersListStartDate
            })
    }

    componentDidUpdate(lastProps) {
        const { vendors } = this.props;
        const { ordersListStartDate, ordersListEndDate } = lastProps;
        const { fetchOrderStats } = this.props.actions;
            if (this.props.vendors !== lastProps.vendors || this.props.ordersListStartDate !== ordersListStartDate ||
                this.props.ordersListEndDate !== ordersListEndDate) {
                const [...disabledVendors] = vendors.filter((vendor) => { return vendor.selected === false }).values();
                fetchOrderStats({
                    opts: {
                        toDate: this.props.ordersListEndDate,
                        vendorIDBlacklist: disabledVendors.map(vendor => vendor.vendorID)
                    },
                    fromDate: this.props.ordersListStartDate
                })
            }
    }


    render() {
        const { showOrderSummaryView, fetchOrderStatsInProgress } = this.props;
        if(fetchOrderStatsInProgress){
            return <LoadingSpinner />
        }
        return (<div>
            <div className={ (showOrderSummaryView ? "" : "d-none") +  " d-md-block summary-chart-wrapper"} id="collapseOrderSummary">
            <Segment basic >
                <Grid stackable container stretched style={{marginBottom: "20px"}}>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <OrdersCostSummary />
                            <Responsive {...Responsive.onlyTablet}>
                            <div style={{marginBottom: "10px"}} />
                            </Responsive>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <OrderNumberSummary />
                            <Responsive {...Responsive.onlyTablet}>
                            <div style={{marginBottom: "10px"}} />
                            </Responsive>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <OrderPaymentTypeSummary />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <OrderDeliveryTypeSummary />
                        </Grid.Column>
                </Grid>
                </Segment>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    showOrderSummaryView: state.orders.showOrderSummaryView,
    vendors: state.vendor.vendors,
    ordersListStartDate: state.orders.ordersListStartDate,
    ordersListEndDate: state.orders.ordersListEndDate,
    fetchOrderStatsInProgress: state.orders.fetchOrderStatsInProgress
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ fetchOrderStats }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSummary);