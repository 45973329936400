import React, {Component} from 'react';
import {Container, Row} from 'reactstrap';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {IntlProvider} from "react-intl";
import {fetchPayout} from "../../actions/PayoutActions";
import PayoutCard from "../payouts/mobile/PayoutCard";
import PayoutTransactionRow from "./PayoutTransactionRow";
import Moment from "moment";

class Payout extends Component {

    componentDidMount() {
        const {match, actions} = this.props;
        const payoutId = match.params.payoutId;

        // Send an initial API request to show results
        actions.fetchPayout({
                payoutId: payoutId
            }
        )
    }

    render() {
        const {fetchInProgress, payout, vendors, user} = this.props;

        if (fetchInProgress) {
            console.info("Fetch in progress")

            return (<IntlProvider locale={navigator.language}>
                <Container fluid className="page-content">
                    <Row className="summary-title-row">
                        <div className="col-sm-12">
                            <h1>Retrieving Payout...</h1>
                        </div>
                    </Row>
                </Container>
            </IntlProvider>)
        }

        if (!payout) {
            console.info("Payout not found")

            // Not found
            return (<IntlProvider locale={navigator.language}>
                <Container fluid className="page-content">
                    <Row className="summary-title-row">
                        <div className="col-sm-12">
                            <h1 className="d-none d-md-block" style={{padding: "0px", color: "grey"}}>Payout not
                                found</h1>
                            <h3 className="d-md-none" style={{padding: "0px", color: "grey", marginTop: "0px"}}>Payout
                                not found</h3>
                        </div>
                    </Row>
                </Container>
            </IntlProvider>)
        }

        const contentsGroupedByDate = this.groupAndSortByDate(payout.payoutContents)


        return (
            <IntlProvider locale={navigator.language}>
                <Container fluid className="page-content">

                    <div className="mt-4 mx-2">
                        <PayoutCard key={payout.id} vendors={vendors} {...payout} />
                    </div>

                    <div className="card mt-4 mx-2">
                        <div data-cy="payout-transactions" className="card-body p-0">
                            { ! contentsGroupedByDate && <div className="payout-event-header px-4 py-3">Payout contents unavailable</div>}
                            { contentsGroupedByDate && Object.entries(contentsGroupedByDate).map(([date, events]) => (
                                    <div>
                                        <div className="bg-grey px-4 py-2 font-weight-bold">{date}</div>
                                        {
                                            events
                                                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map(event => (<PayoutTransactionRow key={event.id} user={user} {...event} />))
                                        }
                                    </div>
                                ))

                            }
                        </div>
                    </div>

                </Container>
            </IntlProvider>
    );

    }

    groupAndSortByDate(contents) {

        if(! contents || contents.length < 1){
            return null;
        }

        // Step 1: Group by date
        const grouped = contents.reduce((acc, item) => {
            const date = Moment(item.timestamp.split("T")[0]).format("ddd D MMM"); // Extract date part
            acc[date] = acc[date] || [];
            acc[date].push(item);
            return acc;
        }, {});

        // Step 2: Sort each date's entries by timestamp (newest first)
        Object.keys(grouped).forEach(date => {
            grouped[date].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        });

        // Step 3: Sort the dates in descending order (newest first)
        return Object.fromEntries(
            Object.entries(grouped).sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
        );
    }

    }

const mapStateToProps = (state) => {
    return {
        vendors: state.vendor.vendors,
        fetchInProgress: state.payouts.fetchInProgress,
        payout: state.payouts.result,
        error: state.payouts.error,
        user: state.user.user
    }
}

const mapDispatchToActions = (dispatch) => {
    return {
        actions: bindActionCreators({fetchPayout}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToActions)(Payout);