import React, { Component } from 'react'
import PayoutCard from './PayoutCard'
export default class PayoutsCards extends Component {

  render() {
      const {payouts, vendors} = this.props;

      return (
          <div>
              {payouts.map(p => <PayoutCard key={p.id} {...p} vendors={vendors} />)}
          </div>
      )
  }
}
