import { put, takeLatest } from 'redux-saga/effects';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { logout } from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';
import * as PayoutActions from "../../actions/PayoutActions";


function* fetchPayout(action) {
    try{
        let apiInstance = ApiClientProvider.getPayoutsControllerApiInstance();
        const {payoutId} = action.payload

        const {data} = yield apiInstance.getPayoutUsingGET(
            {
                payoutId: payoutId,
            }
        );

        yield put({
            type: PayoutActions.fetchPayoutSuccess().type,
            payload: {
                result: data
            }
        })
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: PayoutActions.fetchPayoutFailed().type, payload: "Unable to fetch payout"});
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put({ type: PayoutActions.fetchPayoutFailed().type, payload: e.message});
    }
}


function* fetchPayouts(action) {
    try{
        let apiInstance = ApiClientProvider.getPayoutsControllerApiInstance();
        const {vendorIdBlacklist, itemsPerPage, page} = action.payload

        const {data} = yield apiInstance.getPayoutsUsingGET(
            {
                vendorIdsNotWanted: vendorIdBlacklist,
                itemsPerPage: itemsPerPage,
                pageNumber: page - 1 // API is zero indexed
            }
        );
        const results = data.paginatedResultList
        const total = data.totalResults

        yield put({
            type: PayoutActions.fetchPayoutsSuccess().type,
            payload: {
                results: results,
                total: total,
                itemsPerPage: itemsPerPage,
                page: page
            }
        })
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: PayoutActions.fetchPayoutsFailed().type, payload: "Unable to fetch payouts"});
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put({ type: PayoutActions.fetchPayoutsFailed().type, payload: e.message});
    }
}

export default function* rootPayoutsSaga() {
    yield takeLatest(PayoutActions.fetchPayouts().type, fetchPayouts);
    yield takeLatest(PayoutActions.fetchPayout().type, fetchPayout);
}