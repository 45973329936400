import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
import OrdersTableRow from './OrdersTableRow';
import {userHasPermission} from "../../../../utils/helpers/User";
import {VIEW_ORDERS_CUSTOMER_INFO} from "../../../../utils/helpers/Permission";

export default class OrdersTableView extends Component {
    renderTableRows = (orders, user, vendors) => {
        return orders.map(order => <OrdersTableRow key={order.id} user={user} vendors={vendors} {...order} />)
    }
    render() {
        const { tableData, user, vendors } = this.props;

        return (
            <Table celled definition unstackable striped>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        { vendors.size > 1 && <Table.HeaderCell>Partner</Table.HeaderCell> }
                        <Table.HeaderCell>Reference</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        {userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && <Table.HeaderCell>Name</Table.HeaderCell>}
                        {userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && <Table.HeaderCell>Address</Table.HeaderCell>}
                        <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Payment</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.renderTableRows(tableData, user, vendors)}
                </Table.Body>
            </Table>
        )
    }
}
