import React, {Component} from 'react';
import {Table} from 'semantic-ui-react';
import PayoutTableRow from './PayoutTableRow';

export default class PayoutsTable extends Component {

    render() {
        const {payouts, vendors} = this.props;
        return (<div>
                <Table data-cy="payouts-table" celled definition unstackable striped className="mt-4">
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                            {vendors.size > 1 && <Table.HeaderCell collapsing>Partner</Table.HeaderCell>}
                            <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Date</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Account</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Sort Code</Table.HeaderCell>
                            <Table.HeaderCell singleLine textAlign="right">Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {payouts.map(p => <PayoutTableRow key={p.id} {...p} vendors={vendors} />)}
                    </Table.Body>
                </Table>
            </div>)
    }
}
