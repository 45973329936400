import React, { Component } from 'react';

import CurrencyDisplay from "../../components/money/CurrencyDisplay";
import {withRouter} from "react-router-dom";
import {
  FaChevronDown,
  FaChevronRight,
} from "react-icons/all";
import {userHasPermission} from "../../utils/helpers/User";
import {VIEW_PAYOUTS} from "../../utils/helpers/Permission";

/**
 * This card shows all the events on an order (placed, adjusted cancelled) and which payout(s) these event(s) are in
 * For each event, the user can also drill into the line items of each event (e.g scoffable commission = £5, vat = £2 etc)
 *
 * The user can also click on a payout to see the other contents of that payout (i.e which other orders are in that
 * payout)
 */
class OrderEventBreakdownCard extends Component {

  constructor(props) {
    super(props);
    this.state = { expandedEventIds: [] }
  }

  render() {
    const {
      eventChain,
      history,
      user
    } = this.props;

    if(! eventChain?.ledgerEventList || eventChain?.ledgerEventList.length === 0){
      return (
          <div className="card mb-4 pb-2">
            <div data-cy="payouts-card" className="card-body">
              <h5 className="card-title">Payouts</h5>

              <div className="mt-4">Payout information for this order is unavailable</div>
            </div>
          </div>
      )
    } else {
      const payoutGroupedEventChain = this.groupAndSortByPayout(eventChain.ledgerEventList);

      return (
          <div className="card mb-2 pb-2">
            <div data-cy="payouts-card" className="card-body">
              <h5 className="card-title">Payouts</h5>

              {payoutGroupedEventChain.payouts.map(p => this.renderOrderPayout(p, user, history))}

            </div>
          </div>
      );
    }

  }


  renderOrderPayout(payout, user, history) {

    return <div key={payout.id} className="mt-4" >
      <div className="payout-header p-3 border bg-grey rounded-top">

          { payout?.id > 0 &&
              <div data-cy="payout-title">
                {userHasPermission(user, VIEW_PAYOUTS) && <span className="red-link" onClick={() => history.push("/payouts/" + payout.id)}>Payout {payout.id}</span>}
                {!userHasPermission(user, VIEW_PAYOUTS) && <span>Payout {payout.id}</span>}
              </div>
          }
          { ! payout?.id > 0 && <div data-cy="payout-title"><em>Pending</em></div>}
        <div data-cy="payout-amount" className="font-weight-bold">{this.getDisplayCurrency(payout.netPayoutAmountMinorUnits)}</div>
      </div>
      {payout.ledgerEventList.map( event => this.getOrderEventRow(event)) }
    </div>
  }


  getEventItems(event) {
    const displayOrder = ["ITEM_TOTAL", "CASH_PAYMENT", "SCOFFABLE_PROMOTION", "COMMISSION", "VENDOR_SERVICE_FEE", "VAT"];

    event.eventItems.sort((a, b) => {
      return displayOrder.indexOf(a.type) - displayOrder.indexOf(b.type);
    });

    return event.eventItems.map( item => this.getOrderEventItemRow(event.id, item))

  }

  getOrderEventRow(event) {

    return <div data-cy={"event-" + event.id} key={event.id} className="border-left border-right border-bottom pl-3 pr-3 py-2" onClick={
      () => {
        // Clone list
        let expandedEventIds = [...this.state.expandedEventIds]
        // This shows or hides the item
        expandedEventIds = this.toggleItem(expandedEventIds, event.id);
        // Update the react state
        this.setState({ expandedEventIds: expandedEventIds });
      }
    }>
      <div className="payout-event-header">
        <div className="event-description">
          { this.state.expandedEventIds.includes(event.id) && <FaChevronDown />}
          { ! this.state.expandedEventIds.includes(event.id) && <FaChevronRight />}
          <span className="ml-2">{this.getEventDescription(event)}</span>
        </div>
        <div>{this.getDisplayCurrency(event.eventItems.reduce(
            (sum, item) => sum + item.amountMinorUnits,
            0
        ))}</div>
      </div>

      { this.state.expandedEventIds.includes(event.id) && <ul className="tree mt-2">{ this.getEventItems(event)}</ul> }
    </div>
  }

  getEventDescription(event){
    if(event.description === "New order") {
      return "Order Placed"
    } else if(event.description === "Order cancelled") {
      return "Order Cancelled"
    } else{
      return "Order Adjusted"
    }
  }


  getDisplayCurrency(amountMinorUnits) {
    return amountMinorUnits && <CurrencyDisplay amountMinorUnits={amountMinorUnits}/>
  }

  getOrderEventItemRow(ledgerEventId, item) {

    switch (item.type) {
      case "ITEM_TOTAL":
        return this.genericRow("Item Total", item, ledgerEventId);
      case "CASH_PAYMENT":
        return this.genericRow("Customer Cash", item, ledgerEventId);
      case "SCOFFABLE_PROMOTION":
        return this.genericRow("Scoffable Promotion", item, ledgerEventId);
      case "COMMISSION":
        return this.genericRow("Commission", item, ledgerEventId);
      case "VENDOR_SERVICE_FEE":
        return this.genericRow("Service Fee", item, ledgerEventId);
      case "VAT":
        return this.genericRow("VAT", item, ledgerEventId);
      default:
        return "";
    }
  }

  genericRow(label, item, ledgerEventId) {
    return <li key={ledgerEventId + "-" + item.type}><div className="event-item"><div>{label}</div><div>{this.getDisplayCurrency(item.amountMinorUnits)}</div></div></li>
  }

  toggleItem(array, item) {
    return array.includes(item)
        ? array.filter(i => i !== item)  // Remove if exists
        : [...array, item];              // Add if not exists
  }

  groupAndSortByPayout(eventList) {
    const grouped = eventList.reduce((acc, event) => {
      const { payoutId, eventItems } = event;

      // Sum the amountMinorUnits for this event
      const netAmount = eventItems.reduce((sum, item) => sum + item.amountMinorUnits, 0);

      if (!acc[payoutId]) {
        acc[payoutId] = { ledgerEventList: [], netPayoutAmountMinorUnits: 0 };
      }

      acc[payoutId].ledgerEventList.push(event);
      acc[payoutId].netPayoutAmountMinorUnits += netAmount;

      return acc;
    }, {});

    return {
      payouts: Object.entries(grouped)
          .map(([payoutId, { ledgerEventList, netPayoutAmountMinorUnits }]) => ({
            id: payoutId === "null" ? null : Number(payoutId),
            netPayoutAmountMinorUnits,
            ledgerEventList: ledgerEventList.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
          }))
    };
  }



}


export default withRouter(OrderEventBreakdownCard);
