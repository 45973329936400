import React, { Component } from 'react';
import OrdersCard from "./OrdersCard";

export default class OrdersCardView extends Component {


    render() {
        const {tableData, user, vendors} = this.props;

        return (
            <div>
                {tableData.map(order => <OrdersCard key={order.id} user={user} {...order} vendors={vendors}/>)}
            </div>
        )
    }
}
