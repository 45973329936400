import ApiClientProvider from '../api/ApiClientProvider';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies'
const AUTH_TOKEN_COOKIE = "scoffable.partnercentre.token";
const DOWNLOAD_TOKEN_COOKIE = "scoffable.partnercentre.downloadToken";
const USER_ID_COOKIE = "scoffable.partnercentre.uid";

export const setCookies = (data) => {
    bake_cookie(AUTH_TOKEN_COOKIE, data.authToken)
    bake_cookie(DOWNLOAD_TOKEN_COOKIE, data.downloadToken)
    bake_cookie(USER_ID_COOKIE, data.userId)
    ApiClientProvider.refreshCookies();
}

export const getCookies = () => {
    return {
        authToken: read_cookie(AUTH_TOKEN_COOKIE),
        downloadToken: read_cookie(DOWNLOAD_TOKEN_COOKIE),
        userId: read_cookie(USER_ID_COOKIE),
    };
}

export const removeCookies = () => {
    delete_cookie(AUTH_TOKEN_COOKIE);
    delete_cookie(DOWNLOAD_TOKEN_COOKIE)
    delete_cookie(USER_ID_COOKIE);
}

export const isCookiesExists = () => {
    let object = getCookies();
    const allCookiesExist = object &&
        object.authToken && object.authToken.length !== 0
        && object.userId && object.userId.length !== 0

    if(!allCookiesExist){
        removeCookies()
    }
    return allCookiesExist
}