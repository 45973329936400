export function fetchPayouts(payload){
    return {
        type: "FETCH_PAYOUTS",
        payload: payload
    }
}

export function fetchPayoutsSuccess(payload){
    return {
        type: "FETCH_PAYOUTS_SUCCESS",
        payload: payload
    }
}

export function fetchPayoutsFailed(error){
    return {
        type: "FETCH_PAYOUTS_FAILED",
        payload: error
    }
}

export function fetchPayout(payload){
    return {
        type: "FETCH_PAYOUT",
        payload: payload
    }
}

export function fetchPayoutSuccess(payload){
    return {
        type: "FETCH_PAYOUT_SUCCESS",
        payload: payload
    }
}

export function fetchPayoutFailed(error){
    return {
        type: "FETCH_PAYOUT_FAILED",
        payload: error
    }
}