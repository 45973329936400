import '../../css/OrderDetails.css';
import '../../css/VendorDashboard.css';
import 'react-dates/lib/css/_datepicker.css';

import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Container, Row } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOrderDetails, fetchOrderVendorLedgerEventChain } from "../../actions/OrderActions";

import OrderDetailsCustomerDetailsCard from './OrderDetailsCustomerDetailsCard';
import OrderDetailsItemsCard from './OrderDetailsItemsCard';
import OrderDetailsSummaryCard from './OrderDetailsSummaryCard';
import OrderEventBreakdownCard from './OrderEventBreakdownCard';
import {withRouter} from "react-router-dom";


class OrderDetails extends Component {

  componentDidMount() {
    this.getOrderDetailsFromAPI();
  }

  getOrderDetailsFromAPI(){
    const {match, actions} = this.props;
    const orderID = match.params.orderID;
    actions.fetchOrderDetails({orderID});
    actions.fetchOrderVendorLedgerEventChain({orderID});
  }

  render() {
    const {
      orderNotFound,
      orderData,
      user,
      orderLoading,
      eventChain,
      eventChainLoading,
      vendors,
      history
    } = this.props;

    if (orderNotFound) {
      return <IntlProvider locale={navigator.language}>
        <Container fluid className="page-content">

          <div data-cy='order-not-found-card' className="card m-4">
            <div className="card-body">
              <h5 className="card-title">Order not found</h5>
              <div>Was this order cancelled?</div>
            </div>
          </div>

        </Container>
      </IntlProvider>
    }

    if (!orderData || orderLoading || eventChainLoading) {
      return <IntlProvider locale={navigator.language}>
        <Container fluid className="page-content">
          <Row className="summary-title-row">
            <div className="col-sm-12">
              <h1>Retrieving Order Data...</h1>
            </div>
          </Row>
        </Container>
      </IntlProvider>
    }

    return <IntlProvider locale={navigator.language}>
      <Container fluid className="page-content">
        <Row className="summary-title-row">
          <div className="col-sm-12">
            <h1 className="d-none d-md-block" style={{padding: "0px", color: "grey"}}>SCOFF-{orderData.id}</h1>
            <h3 className="d-md-none"
                style={{padding: "0px", color: "grey", marginTop: "0px"}}>SCOFF-{orderData.id}</h3>
          </div>
        </Row>
        <Row>
          <div className="col-lg-6">
            <OrderDetailsSummaryCard orderData={orderData} vendors={vendors}/>
            <OrderDetailsCustomerDetailsCard orderData={orderData} user={user}/>
          </div>
          <div className="col-lg-6">
            <OrderDetailsItemsCard orderData={orderData}/>
            <OrderEventBreakdownCard eventChain={eventChain} user={user} history={history} />
          </div>
        </Row>
      </Container>
    </IntlProvider>

  }
}

const mapStateToProps = (state) => {
  return {
    vendors: state.vendor.vendors,
    orderData: state.orders.orderData,
    orderNotFound: state.orders.orderNotFound,
    orderLoading: state.orders.orderLoading,
    eventChainLoading: state.orders.eventChainLoading,
    eventChain: state.orders.orderVendorLedgerEventChain,
    user: state.user.user
  }
}

const mapDispatchToActions = (dispatch) => {
  return {
    actions: bindActionCreators({fetchOrderDetails, fetchOrderVendorLedgerEventChain}, dispatch)
  }
}

const connectedComponent = connect(mapStateToProps, mapDispatchToActions)(OrderDetails)

export default withRouter(connectedComponent)