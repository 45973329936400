import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { Row } from 'reactstrap';

class OrderDetailsItemsCard extends Component {

  formatNumberToCurrency(number){
    if(number != null){
      /* eslint-disable react/style-prop-object */
      return (
      <FormattedNumber
        value={number}
        currency="GBP"
        style="currency"
        minimumFractionDigits={2}
        maximumFractionDigits={2}>
        </FormattedNumber>
      );
      /* eslint-enable react/style-prop-object */
    }

    return this;
  }

  renderSubItems(){

    if(this.props.orderData.orderItems != null){
      return (
        <ul className="list-group list-group-flush">
          {this.props.orderData.orderItems.map(this.renderItem.bind(this))}
          {this.renderTotalLine("Sub Total", this.props.orderData.subTotal)}
          {this.renderChargeLine("Delivery Fee", this.props.orderData.deliveryCharge)}
          {this.renderChargeLine("Service Fee", this.props.orderData.serviceFee)}
          {this.renderChargeLine("Card Surcharge", this.props.orderData.cardSurcharge)}
          {this.renderTotalLine("Total", this.props.orderData.total)}
        </ul>
      );
    }
    return null;
  }

  renderTotalLine(totalName, totalData){
    return(
      <li className="list-group-item">
        <Row>
          <div className="col-7 col-xl-8 item-name-col">
            <b>{totalName}</b>
          </div>

          <div className="col text-right">
            <b>{this.formatNumberToCurrency(totalData)}</b>
          </div>
        </Row>
      </li>
    );
  }

  renderChargeLine(chargeName, chargeData){
    if(chargeData != null){
      return(
        <li className="list-group-item">
          <Row>
            <div className="col-7 col-xl-8 item-name-col">
              {chargeName}
            </div>

            <div className="col text-right">
              {this.formatNumberToCurrency(chargeData)}
            </div>
          </Row>
        </li>
      );
    }
  }

  renderItem(item){
    return (
      <li className="list-group-item">
        <Row>
          <div className="quant-col">
            <span className="badge badge-primary badge-pill quantity-badge">{item.quantity}x</span>
          </div>

          <div className="col-7 col-xl-8 item-name-col">
            {item.name}
          </div>

          <div className="col text-right">
            {this.formatNumberToCurrency(item.lineTotal)}
          </div>
        </Row>
        {this.renderOptionCategories(item)}
      </li>
    );
  }

  renderOptionCategories(item){
    if(item.optionCategories != null){
      return(
        <Row>
          <div className="col-sm">
            <ul className="list-group list-group-flush">
              {item.optionCategories.map(this.renderOptionCategory.bind(this))}
            </ul>
          </div>
        </Row>
      );
    }
  }

  renderOptionCategory(optionCategory){
    return(
      <li className="list-group-item sub-item-entry">
        - {optionCategory.name} ({this.renderOptionCategoryOptions(optionCategory.options)})
      </li>
    );
  }

  renderOptionCategoryOptions(options){
    // Create an array of presentable option strings
    let presentableOptions = [];
    if(options != null){
      for(let i = 0; i<options.length; i++){

        let optionPrice;
        if(options[i].price > 0){
          optionPrice = (<span><i>&nbsp;:&nbsp;+{this.formatNumberToCurrency(options[i].price)}</i></span>)
        }

        const optionDescriptor  = (<span>{options[i].name}{optionPrice}
          {(i > 0 && i<options.length-1)? ", " : ""}</span>);
        presentableOptions.push(optionDescriptor);
      }
    }

    return presentableOptions;
  }
  
  render() {
    return (
      <div data-cy='order-items-card' className="card mb-4 order-items-card">
      <div className="card-body">
        <h5 className="card-title">Order Items</h5>
        {this.renderSubItems()}
      </div>
    </div>
    );
  }
}

export default OrderDetailsItemsCard;