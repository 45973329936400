import React, { Component } from 'react';
import {Alert, Container, Row} from 'reactstrap';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BalanceSummaries from "./BalanceSummaries";
import {
  fetchPayouts
} from "../../actions/PayoutActions";
import PayoutsTable from "./desktop/PayoutsTable";
import {Pagination, Responsive, Segment} from "semantic-ui-react";
import PayoutsCards from "./mobile/PayoutsCards";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import FirstPaginationIcon from "../../images/icons/pagination/first_page.svg";
import LastPaginationIcon from "../../images/icons/pagination/last_page.svg";
import NextPaginationIcon from "../../images/icons/pagination/next_page.svg";
import PreviousPaginationIcon from "../../images/icons/pagination/previous_page.svg";
import '../../css/PaginationControl.css'
import {userHasPermission} from "../../utils/helpers/User";
import {VIEW_BALANCES} from "../../utils/helpers/Permission";

class Payouts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      itemsPerPage: 10,
      total: 0
    };
  }

  handleOnUpdate = (e, { width }) => this.setState({ width })

  componentDidMount() {
    // Send an initial API request to show results
    this.triggerApiRequest({
      'vendorIdBlacklist': this.props.vendorIdBlacklist,
      'itemsPerPage': this.props.itemsPerPage,
      'page': this.props.page,
    })
  }

  triggerApiRequest(opts){
    this.props.actions.fetchPayouts(opts);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page
        || prevProps.itemsPerPage !== this.props.itemsPerPage
        || prevProps.vendors !== this.props.vendors
        || JSON.stringify(prevProps.vendorIdBlacklist) !== JSON.stringify(this.props.vendorIdBlacklist)) {

      this.triggerApiRequest({
        'vendorIdBlacklist': this.props.vendorIdBlacklist,
        'itemsPerPage': this.props.itemsPerPage,
        'page': this.props.page,
      })

    }
  }



  render() {
    const { fetchInProgress, payouts, vendors, error, vendorIdBlacklist, page, total, itemsPerPage, user} = this.props;
    const { width } = this.state;
    const isMobile = width <= 768;

    const filteredVendors = vendors.filter( vendor => ! vendorIdBlacklist.some(id => id === vendor.vendorID))
    const filteredVendorCount = Array.from(filteredVendors?.values()).length

    const totalPages = (Math.ceil(total / itemsPerPage) || 1)

    return (
      <div className="mh-100 minh-100 reviews-page-container">
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <Row style={{ margin: "0px" }}>
          <div className="col-sm-12" style={{ padding: "0px" }}>
            <div className="summary-title-row">
              <div className="col-sm-12">
                <div>
                  <h1 data-cy="page-title" className="d-none d-md-inline-block" style={{ padding: "0px", color: "grey" }}>Payouts</h1>
                  <h3 data-cy="page-title" className="d-md-none d-inline-block" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>Payouts</h3>
                </div>
              </div>
            </div>
          </div>
          </Row>
        {userHasPermission(user, VIEW_BALANCES) && <BalanceSummaries vendors={filteredVendors} width={width} user={user} />}
        {fetchInProgress && <Container className="mt-3"><LoadingSpinner size={80} /></Container>}
        {error &&
            <Alert data-cy="error-card" color="warning" className="mt-3 text-center">
              <strong>{error}</strong><br/>
              Please refresh and try again
            </Alert>}

        {!error &&
            !fetchInProgress &&
            (payouts == null || payouts.length === 0) &&  // We don't have any payouts to show
            (filteredVendorCount > 0) && // We've got at least one vendor selected
            <Alert data-cy="payouts-empty-dataset" color="warning" className="mt-3 text-center">
              No payouts found
            </Alert>}

        {!error && payouts != null && payouts.length > 0 && // Show payouts if we have 
            <Row className="d-block invoice-table-wrapper">

            <Segment basic>
              <Responsive {...Responsive.onlyComputer} fireOnMount={true} onUpdate={this.handleOnUpdate}>
                <PayoutsTable payouts={payouts} vendors={vendors} />
              </Responsive>

              <Responsive {...Responsive.onlyTablet} fireOnMount={true} onUpdate={this.handleOnUpdate}>
                <PayoutsTable payouts={payouts} vendors={vendors}/>
              </Responsive>

              <Responsive {...Responsive.onlyMobile} fireOnMount={true} onUpdate={this.handleOnUpdate}>
                <PayoutsCards payouts={payouts} vendors={vendors} />
              </Responsive>

              {(totalPages > 1) &&
                  <Pagination
                  activePage={page}
                  totalPages={totalPages}
                  className="pagination-control"
                  onPageChange={(event, data) => {
                    this.triggerApiRequest({
                      'vendorIdBlacklist': this.props.vendorIdBlacklist,
                      'itemsPerPage': this.props.itemsPerPage,
                      'page': data.activePage,
                    })
                  }}
                  firstItem={isMobile ? false : {content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
                  lastItem={isMobile ? false : {content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
                  nextItem={isMobile ? false : {content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
                  prevItem={isMobile ? false : {content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}
              />}
            </Segment>
          </Row>

        }
        </div>
    );
  }
}

const mapStateToProps = (state, _ourProps) => {

  const vendorIdBlacklist = Array.from(state.vendor.vendors
      .filter((vendor) => { return vendor.selected === false })
      .map(vendor => vendor.vendorID)
      .values())

  return {
    vendors: state.vendor.vendors,
    fetchInProgress: state.payouts.fetchInProgress,
    payouts: state.payouts.results,
    error: state.payouts.error,
    total: state.payouts.total,
    page: state.payouts.page,
    itemsPerPage: state.payouts.itemsPerPage,
    vendorIdBlacklist: vendorIdBlacklist,
    user: state.user.user
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    actions: bindActionCreators({
      fetchPayouts
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToState)(Payouts);