import React, { Component } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import VendorLogoAddressView from "../../components/vendor/VendorLogoAddressView";
import "../../css/ReviewPercentage.css";
import CurrencyDisplay from "../../components/money/CurrencyDisplay";
import DateDisplay from "../../components/date/DateDisplay";
import {userHasPermissionForVendor} from "../../utils/helpers/User";
import {VIEW_BALANCES} from "../../utils/helpers/Permission";

export default class BalanceSummary extends Component {

    render() {
        const { vendor, isMobile, user } = this.props;
        return (
            <Container className="no-padding no-margin">
                <Grid textAlign="center" className="no-padding no-margin">
                    <Grid.Row key={vendor.vendorID} columns={isMobile ? 1 : 'equal'} centered>
                        <Grid.Column mobile={16} tablet={16} computer={8} className="no-padding no-margin">
                            <VendorLogoAddressView vendorID={vendor.vendorID}/>
                        </Grid.Column>
                        <Grid.Column verticalAlign="top" className="mt-3 pt-2 pb-1 customer-satisfaction">
                            { userHasPermissionForVendor(user, vendor.vendorID, VIEW_BALANCES) && <span className="headline-title">Balance</span> }
                            { userHasPermissionForVendor(user, vendor.vendorID, VIEW_BALANCES) &&
                                <div data-cy="balance" className="headline-value mt-2 mb-2">
                                    <CurrencyDisplay amountMinorUnits={vendor.accountBalance?.balance} />
                                </div>
                            }
                            { userHasPermissionForVendor(user, vendor.vendorID, VIEW_BALANCES) &&
                                <div data-cy="available-balance-and-next-payout-date" className="based-on-text">
                                Available for Payout: <CurrencyDisplay amountMinorUnits={vendor.accountBalance?.availableBalance} /><br />
                                {vendor.accountBalance?.nextPayoutDate &&
                                    <span>Next Payout Date: <DateDisplay date={vendor.accountBalance.nextPayoutDate} format="DD/MM/YY" /></span>
                                }</div>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

}


