import moment from "moment/moment";
import React, {Component} from "react";


export default class DateDisplay extends Component {

    render() {
        const {date, format} = this.props;
        const localFormat = (! format) ? "DD/MM/YYYY" : format;
        return (<span>{moment(date).format(localFormat)}</span>)

    }
}

