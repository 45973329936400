import '../../../../css/OrdersTable.css';
import React, { Component } from 'react';
import Address from "../fields/Address";
import { OrderType } from '../fields/OrderType';
import { PaymentMethod } from '../fields/PaymentMethod';
import { Table } from "semantic-ui-react";
import moment from "moment";
import { FormattedNumber, IntlProvider } from 'react-intl';
import { withRouter } from "react-router-dom";
import {FaQuestionCircle} from 'react-icons/fa';
import { Tooltip } from "reactstrap";
import {userHasPermission} from "../../../../utils/helpers/User";
import {VIEW_ORDERS_CUSTOMER_INFO} from "../../../../utils/helpers/Permission";

class OrdersTableRow extends Component {
  /* eslint-disable react/style-prop-object */

  constructor(props) {
    super(props)
      this.state = {
          nameTooltipOpen: false,
          nameExpiredTooltipId: `nameExpiredTooltip${props.id}`,
          addressExpiredTooltipId: `addressExpiredTooltip${props.id}`,
          addressToolTipOpen: false
      }
  }
  
  toggleNameTooltip = () => {
      this.setState({
          nameTooltipOpen: !this.state.nameTooltipOpen
      })
  }
  
  toggleAddressTooltip = () => {
    this.setState({
        addressToolTipOpen: !this.state.addressToolTipOpen
    })
  }

  render() {
    const { vendorLogo, vendorName, id, submittedDate, type, paymentMethod, total, history, user, vendors } = this.props;
    /* eslint-disable react/style-prop-object */
    return (
      <Table.Row key={id} onClick={() => history.push("/order/" + id)} >
        <Table.Cell textAlign="center" collapsing><img src={vendorLogo} alt="Vendor Logo" className="rounded align-middle table-vendor-logo vendor-logo" style={{ height: "30px" }} /></Table.Cell>
          { vendors.size > 1 && <Table.Cell collapsing>{vendorName}</Table.Cell> }
        <Table.Cell data-cy={"order-" + id} collapsing>{id}</Table.Cell>
        <Table.Cell>{moment(submittedDate).format("D/M/YY HH:mm")}</Table.Cell>
          { userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && this.renderCustomerName()}
          { userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && this.renderCustomerDeliverAddress()}
        <Table.Cell textAlign="center" collapsing><OrderType orderType={type} /></Table.Cell>
        <Table.Cell textAlign="center" collapsing><PaymentMethod paymentMethod={paymentMethod} /></Table.Cell>
        <Table.Cell collapsing textAlign="right">
          <IntlProvider locale={navigator.language}>
            <FormattedNumber
              value={total}
              currency="GBP"
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2} />
          </IntlProvider>
        </Table.Cell>
      </Table.Row>
    )
    /* eslint-enable react/style-prop-object */
  }

    renderCustomerName() {
        const {firstName, surname} = this.props;

        return (
            <Table.Cell>
                {surname && firstName + " " + surname}
                {!surname && <span id={this.state.nameExpiredTooltipId} style={{color: "grey"}}><FaQuestionCircle
                    style={{marginRight: "5px"}}/>
        <Tooltip isOpen={this.state.nameTooltipOpen} placement="right" toggle={this.toggleNameTooltip}
                 target={this.state.nameExpiredTooltipId}>
        User details will expire after 24hrs in order to comply with data privacy laws
        </Tooltip> Expired</span>}
            </Table.Cell>
        )

    }

    renderCustomerDeliverAddress() {
        const { deliveryAddress, type } = this.props;

            return (<Table.Cell>
                {deliveryAddress && <Address {...deliveryAddress} />}
                {!deliveryAddress && type !== "COLLECTION" &&
                    <span id={this.state.addressExpiredTooltipId} style={{color: "grey"}}><FaQuestionCircle
                        style={{marginRight: "5px"}}/>
        <Tooltip isOpen={this.state.addressToolTipOpen} placement="right" toggle={this.toggleAddressTooltip}
                 target={this.state.addressExpiredTooltipId}>
        User details will expire after 24hrs in order to comply with data privacy laws
</Tooltip> Expired</span>}
                {!deliveryAddress && type === "COLLECTION" && <span>N/A</span>}
            </Table.Cell>)
    }
}

export default withRouter(OrdersTableRow);