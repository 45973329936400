import * as PayoutActions from "../../actions/PayoutActions";
const initialState = {
    fetchInProgress: false,
    results: [],
    error: null,
    total: 0,
    page: 1,
    itemsPerPage: 10
};

export default function PayoutsReducer(state = initialState, action){
    switch (action.type) {
        case PayoutActions.fetchPayouts().type:
            return {
                ...state,
                fetchInProgress: true,
                results: null,
                error: null
            }
        case PayoutActions.fetchPayoutsSuccess().type:
            return {
                ...state,
                results: action.payload.results,
                total: action.payload.total,
                fetchInProgress: false,
                error: null,
                orderReviewsDisabled: false,
                itemsPerPage: action.payload.itemsPerPage,
                page: action.payload.page
            }
        case PayoutActions.fetchPayoutsFailed().type:
            return {
                ...state,
                fetchInProgress: false,
                error: action.payload,
                results: []
            }
        case PayoutActions.fetchPayout().type:
            return {
                ...state,
                fetchInProgress: true,
                result: null,
                error: null
            }
        case PayoutActions.fetchPayoutSuccess().type:
            return {
                ...state,
                result: action.payload.result,
                fetchInProgress: false,
                error: null
            }
        case PayoutActions.fetchPayoutFailed().type:
            return {
                ...state,
                result: null,
                fetchInProgress: false,
                error: action.payload
            }
        default:
            return state;
    }
}