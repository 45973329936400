import React, { Component } from 'react';
import moment from "moment";
import "moment/locale/en-gb";
import CurrencyDisplay from "../../components/money/CurrencyDisplay";
import { withRouter } from "react-router-dom";
import {userHasPermission} from "../../utils/helpers/User";
import {VIEW_INVOICES, VIEW_ORDERS} from "../../utils/helpers/Permission";
import {getCookies} from "../../utils/cookie/CookieManager";

moment.locale("en-gb");

/**
 * This shows each event in a payout (e.g order placed, adjusted etc)
 * Unlike OrderEventBreakdownCard, the user cannot drill into the line itemisation here (as it would be a bit too busy)
 *
 * The user can click on a row to go and see that order (and in future: standard invoice)
 */
class PayoutTransactionRow extends Component {

    render() {
        const {
            externalType, // ORDER etc
            externalId, // 7895748 .. id of the order / invoice / whatever
            user
        } = this.props;


        return (
             ((externalType === "STANDARD_INVOICE" && userHasPermission(user, VIEW_INVOICES)) ?
                 <a className="suppress-anchor-style" target="_blank" rel="noopener noreferrer" href={this.getItemDestinationPath(externalType, externalId)}>{this.getRowContent()}</a> :
                 this.getRowContent()
             )
        )


    }

    getRowContent() {
        const {
            id, // DB id of the vendor ledger event
            externalType, // ORDER etc
            externalId, // 7895748 .. id of the order / invoice / whatever
            description,
            eventItems,
            history,
            user
        } = this.props;

        const amountMinorUnits = eventItems?.reduce(
            (sum, item) => sum + item.amountMinorUnits,
            0
        ) ?? 0

        return (<div data-cy={"event-" + id} className="payout-event-header px-4 py-3 border-top-grey" onClick={() =>
            externalType === "ORDER" && userHasPermission(user, VIEW_ORDERS) && history.push(this.getItemDestinationPath(externalType, externalId))
        }>
            <div>
                {this.getItemDisplayText(externalType, externalId, description)}
            </div>
            <div className="font-weight-bold">{amountMinorUnits >= 0 ? "+" : ""}<CurrencyDisplay
                amountMinorUnits={amountMinorUnits}/></div>
        </div>)
    }

    getItemDisplayText(externalType, externalId, description) {
        if (externalType === "ORDER") {
            if (description === "New order") {
                return "Order #" + externalId;
            }

            if (description === "Order cancelled") {
                return "Order #" + externalId + " - Cancelled";
            }

            if (description === "Ownership change") {
                return "Order #" + externalId + " - Ownership change";
            }

            return "Order #" + externalId + " - Adjusted";
        }

        if (externalType === "STANDARD_INVOICE"){
            if(description === "Invoice cancelled"){
                return "Invoice #" + externalId + " - Cancelled";
            } else {
                return "Invoice #" + externalId;
            }
        }

    }


    getItemDestinationPath(type, id) {
        if(type === "ORDER") {
            return "/order/" + id
        }

        if(type === "STANDARD_INVOICE"){
            const baseUrl = process.env.REACT_APP_API_URL
            const downloadToken = getCookies().downloadToken
            return baseUrl + "/downloads/" + downloadToken + "/standardInvoices/" + id
        }
    }

}

export default withRouter(PayoutTransactionRow);